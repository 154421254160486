@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply text-base bg-white;
  }

  body {
    @apply tracking-widest font-base text-[#232323];
  }

  @screen sm {
    body {
      @apply text-[15px];
      -webkit-overflow-scrolling: touch;
    }
  }

  input,
  textarea,
  button {
    @apply rounded-none appearance-none focus:outline-none;
  }

  select {
    @apply focus:outline-none;
  }

  form {
    @apply mb-0;
  }

  ol li {
    counter-increment: count 1;
    @apply before:content-[counter(count)];
  }

  a {
    @apply hover:opacity-70;
  }

  /* Profile */

  .profile p {
    @apply text-sm leading-9 xs:text-xs;
  }

  .profile hr {
    @apply pt-8 mt-8;
  }

  /* Youtube */
  iframe.youtube-16-9 {
    @apply w-full h-auto aspect-video max-w-[1024px] min-h-[576px] mx-auto;
  }

  @screen xs {
    .profile hr {
      @apply pt-7 mt-7;
    }

    iframe.youtube-16-9 {
      @apply w-full h-auto min-h-0 aspect-video max-w-none;
    }
  }
}

@layer components {
  .splide__arrow--prev {
    @apply !left-0;
  }

  .splide__arrow--next {
    @apply !right-0;
  }
}
